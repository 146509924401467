var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { withNotifySentry } from "./helpers";
export function getCustomer() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.get("/api/customer"));
    });
}
export function updateCustomerClient(client) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.put(`/api/customer/clients/${client.id}`, client));
    });
}
export function getCustomerStats(params) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.get("/api/customer/stats", { params }));
    });
}
export function createCustomer(params) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.post("/api/customer", params));
    });
}
export function updateCustomer(params) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.put("/api/customer", params));
    });
}
export function getCustomerInvoices(params) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.get("/api/customer/invoices", { params }));
    });
}
export const customerInvoicePath = (id) => `/api/customer/invoices/${id}`;
export function getCustomerInvoice(params) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.get(customerInvoicePath(params.id)));
    });
}
