import styled from "styled-components";
export const GridContainer = styled.div `
  display: grid;
  grid-template-rows: auto;
  grid-gap: 0.25em 1em;
  break-inside: avoid;
  width: 100%;
`;
export const GridItem = styled.div `
  grid-column: auto;
  grid-row: auto;

  @media print {
    width: max-content;
    break-inside: avoid;
  }
`;
export const HeadingGridItem = styled(GridItem) `
  margin-bottom: 1em;
`;
