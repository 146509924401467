import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "reduxStore";
import { useParams } from "react-router-dom";
import { selectCustomerInvoice } from "selectors/customer";
import { getCustomerInvoiceAction } from "slices/customer";
import styled from "styled-components";
import { GridContainer, GridItem, HeadingGridItem } from "components/shared/layout/Grid";
import FlexContainer from "components/shared/layout/FlexContainer";
import Spinner from "components/shared/Spinner";
import Paragraph from "components/shared/primitives/Paragraph";
import { H2, H3, H4 } from "components/shared/primitives/Heading";
import { unitsTranslations } from "components/helpers/translations";
import Link from "components/shared/primitives/Link";
import InvoiceCallItemsGrid from "./InvoiceCallItemsGrid";
import { customerInvoicePath } from "api/customer";
import Icon from "components/shared/primitives/Icon";
const PositionsGridContainer = styled(GridContainer) `
  grid-template-columns: 2fr 4fr 3fr 2fr;
`;
export default function CustomerInvoice() {
    const dispatch = useAppDispatch();
    const invoiceState = useSelector(selectCustomerInvoice);
    const { invoiceId } = useParams();
    const [showInfoText, setShowInfoText] = useState(false);
    const { data: invoice, loading } = invoiceState;
    const toggleInfoText = () => {
        setShowInfoText(!showInfoText);
    };
    useEffect(() => {
        if (!invoiceId)
            return;
        const params = { id: invoiceId };
        dispatch(getCustomerInvoiceAction(params));
    }, [invoiceId]);
    if (loading || !invoiceId || !invoice) {
        return (React.createElement(FlexContainer, { flexDirection: "column", fullHeight: true },
            loading && React.createElement(Spinner, { color: "primary" }),
            !loading && !invoice && React.createElement(Paragraph, null, "Rechnung konnte nicht geladen werden.")));
    }
    const { title, pdf, positions } = invoice.sevdeskInvoice;
    const { invoiceCallItems } = invoice;
    const invoiceCallItemsInFlat = invoiceCallItems.filter((i) => !!i.secondsInFlat);
    const invoiceCallItemsOutsideFlat = invoiceCallItems.filter((i) => !!i.roundedSecondsOutsideFlat);
    return (React.createElement(FlexContainer, { style: { margin: "0 3em", paddingBottom: "4em" }, flexDirection: "column" },
        React.createElement(H2, null, title),
        React.createElement(FlexContainer, { gap: "4em", flexDirection: "column", alignItems: "flex-start" },
            React.createElement(FlexContainer, { gap: "1em", flexDirection: "column", alignItems: "flex-start" },
                React.createElement(H3, null, "Positionen"),
                React.createElement(PositionsGridContainer, null,
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Menge")),
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Name")),
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Preis pro Einheit (\u20AC)")),
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Preis (\u20AC)")),
                    positions.map((p) => (React.createElement(Fragment, { key: p.id },
                        React.createElement(GridItem, null, `${p.quantity} (${unitsTranslations[p.unit] || "pauschal"})`),
                        React.createElement(GridItem, null, p.name),
                        React.createElement(GridItem, null, p.pricePerUnit.toFixed(2)),
                        React.createElement(GridItem, null, p.price.toFixed(2)))))),
                pdf && (React.createElement(Link, { download: pdf.name, href: `data:application/pdf;base64,${pdf.content}` }, "Download PDF"))),
            React.createElement(FlexContainer, { gap: "1em", flexDirection: "column", alignItems: "flex-start" },
                React.createElement(H3, null, "Einzelverbindungen"),
                invoiceCallItems.length > 0 ? (React.createElement(FlexContainer, { gap: "2em", flexDirection: "column", alignItems: "flex-start" },
                    invoiceCallItemsInFlat.length > 0 && (React.createElement(FlexContainer, { flexDirection: "column", gap: "1em", alignItems: "flex-start" },
                        React.createElement(H4, { color: "darkGray" }, "Monatliches Kontingent"),
                        React.createElement(InvoiceCallItemsGrid, { items: invoiceCallItemsInFlat }))),
                    invoiceCallItemsOutsideFlat.length > 0 && (React.createElement(FlexContainer, { flexDirection: "column", gap: "1em", alignItems: "flex-start" },
                        React.createElement(H4, { color: "darkGray" }, "Abrechnung nach Minuten"),
                        React.createElement(InvoiceCallItemsGrid, { items: invoiceCallItemsOutsideFlat, outsideFlat: true }))))) : (React.createElement("span", null, "Keine Einzelverbindungen vorhanden")),
                React.createElement(FlexContainer, { gap: "0.5rem", alignItems: "baseline" },
                    React.createElement(Link, { download: true, href: `${customerInvoicePath(invoiceId)}.csv` }, "Download CSV"),
                    React.createElement(Icon, { onClick: toggleInfoText, icon: "info", width: "1.1em", height: "1.1em", cursor: "pointer" })),
                showInfoText && (React.createElement(Fragment, null,
                    React.createElement(Paragraph, null, "Wichtiger Hinweis: Einzelverbindungsnachweise werden ab sofort ausschlie\u00DFlich im Dateiformat \u201E.csv\u201C versendet."),
                    React.createElement(Paragraph, null,
                        React.createElement("b", null, "So zeigen Sie die CSV-Daten in einer Tabelle an:"),
                        React.createElement("br", null),
                        "1. Speichern Sie die CSV-Datei lokal ab.",
                        React.createElement("br", null),
                        "2. \u00D6ffnen Sie Microsoft Excel.",
                        React.createElement("br", null),
                        "3. Klicken Sie auf den Reiter \u201EDaten\u201C und w\u00E4hlen Sie \u201EDaten abrufen\u201C.",
                        React.createElement("br", null),
                        "4. W\u00E4hlen Sie \u201EAus Datei\u201C und anschlie\u00DFend \u201EAus Text/CSV\u201C.",
                        React.createElement("br", null),
                        "5. W\u00E4hlen Sie die zuvor gespeicherte CSV-Datei aus und klicken Sie auf \u201EImportieren\u201C.",
                        React.createElement("br", null),
                        "6. Klicken Sie auf \u201ELaden\u201C, um die Tabelle anzuzeigen."),
                    React.createElement(Paragraph, null,
                        React.createElement("b", null, "Zusatz: Falls das Feld \u201ENummer\u201C nicht korrekt dargestellt wird:"),
                        React.createElement("br", null),
                        "1. W\u00E4hlen Sie den Reiter \u201EDaten\u201C.",
                        React.createElement("br", null),
                        "2. Markieren Sie die Spalte \u201ENummer\u201C.",
                        React.createElement("br", null),
                        "3. Klicken Sie im Men\u00FCband auf \u201EText in Spalten\u201C.",
                        React.createElement("br", null),
                        "4. Klicken Sie zwei Mal auf \u201EWeiter\u201C.",
                        React.createElement("br", null),
                        "5. W\u00E4hlen Sie das Format \u201EText\u201C aus.",
                        React.createElement("br", null),
                        "6. Klicken Sie auf \u201EFertigstellen\u201C."),
                    React.createElement(Paragraph, null,
                        React.createElement("b", null, "Speichern Sie die Datei, um Ihre \u00C4nderungen zu sichern."),
                        React.createElement("br", null),
                        "1. Gehen Sie zu „Datei“ > „Speichern unter“.",
                        React.createElement("br", null),
                        "2. W\u00E4hlen Sie als Dateityp \u201EExcel-Arbeitsmappe\u201C aus.",
                        React.createElement("br", null),
                        "3. Speichern Sie die Datei an einem gew\u00FCnschten Ort.")))))));
}
