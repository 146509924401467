import styled from "styled-components";
import { style as styleConfig } from "../../../config";
const { media, colors } = styleConfig;
export const Base = styled.h1 `
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  line-height: 1.4em;
  margin: 0;
  margin-bottom: ${(p) => (p.solo ? "0" : "0.75em")};
  padding: 0;
  color: ${(p) => (p.color ? colors[p.color] : colors.primary)};

  @media print {
    page-break-after: always;
    margin-top: 40%;
  }
`;
export const H1 = styled(Base) `
  font-size: 3em;
  text-align: center;

  @media (${media.desktop}) {
    font-size: 6em;
  }
`;
export const H2 = styled(Base).attrs({ as: "h2" }) `
  font-size: 2em;
  ${(p) => p.withSubtitle && "margin-bottom: 0.1em;"}
`;
export const H3 = styled(Base).attrs({ as: "h3" }) `
  font-size: 1.25em;
  margin-bottom: 0.25em;
`;
export const H4 = styled(Base).attrs({ as: "h4" }) `
  font-size: 1.1em;
  margin-bottom: 0.25em;
`;
export const PrintOnlyHeading = styled.h1 `
  display: none;
  font-size: 8mm;
  margin-bottom: 1mm;

  @media print {
    display: inherit;
  }
`;
