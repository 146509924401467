var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import Burger from "./Burger.svg";
import Calendar from "./Calendar.svg";
import CalendarThick from "./CalendarThick.svg";
import CheckboxChecked from "./CheckboxChecked.svg";
import CheckboxUnchecked from "./CheckboxUnchecked.svg";
import ChevronLeft from "./ChevronLeft.svg";
import ChevronRight from "./ChevronRight.svg";
import Clock from "./Clock.svg";
import Comment from "./Comment.svg";
import Cross from "./Cross.svg";
import Hourglass from "./Hourglass.svg";
import Moon from "./Moon.svg";
import PlainPhone from "./PlainPhone.svg";
import ShiftEmpty from "./ShiftEmpty.svg";
import ShiftHalf from "./ShiftHalf.svg";
import Sun from "./Sun.svg";
import Trash from "./Trash.svg";
import Info from "./Info.svg";
import { style as styleConfig } from "../../../../config";
const { media, colors } = styleConfig;
const icons = {
    burger: Burger,
    calendar: Calendar,
    calendarThick: CalendarThick,
    checkboxChecked: CheckboxChecked,
    checkboxUnchecked: CheckboxUnchecked,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    clock: Clock,
    comment: Comment,
    cross: Cross,
    hourglass: Hourglass,
    moon: Moon,
    plainPhone: PlainPhone,
    trash: Trash,
    shiftEmpty: ShiftEmpty,
    shiftHalf: ShiftHalf,
    sun: Sun,
    info: Info,
};
function UnstyledIcon(props) {
    const { icon } = props, rest = __rest(props, ["icon"]);
    const SelectedIcon = icons[icon];
    if (SelectedIcon) {
        return React.createElement(SelectedIcon, Object.assign({}, rest));
    }
    else {
        return null;
    }
}
const Icon = styled(UnstyledIcon) `
  ${(p) => css `
    display: inline-block;
    flex-shrink: 0;
    margin: ${p.margin ? `${p.margin}` : "0 1em 0 0"};

    opacity: ${p.disabled ? "0.3" : "1.0"};

    height: ${p.height || "1.3em"};
    width: ${p.width || "1.3em"};
    color: ${p.color ? colors[p.color] || p.color : "inherit"};
    cursor: ${p.cursor || "inherit"}
      ${p.medium &&
    css `
        height: 50px;
        width: 50px;
      `}
      ${p.large &&
    css `
        height: 100px;
        width: 100px;
      `}
      @media (${media.desktop}) {
      ${p.large &&
    css `
        height: 50px;
        width: 50px;
      `};
    }
  `};
`;
export function IconForDaytime(props) {
    const { daytime } = props, rest = __rest(props, ["daytime"]);
    const hour = moment(daytime).get("hour");
    const icon = hour >= 6 && hour < 21 ? "sun" : "moon";
    return React.createElement(Icon, Object.assign({}, rest, { icon: icon }));
}
export default Icon;
