import { createGlobalStyle } from "styled-components";

import normalize from "normalize.css";
import fonts from "./fonts.css";

import { style as styleConfig } from "./config";

export const GlobalStyle = createGlobalStyle`
  ${normalize[0][1]}
  ${fonts[0][1]}

  @page {
    size: a4 landscape;
    margin: 10mm;
  }

  html, body, #app {
    width: 100%;
    height: 100%;
    margin: 0;

    @media print {
      font-size: 5mm;
    }
  }

  body, input, textarea, button {
    font-family: 'Roboto', sans-serif;
    font-size: 4.5vw;

    @media(${styleConfig.media.desktop}) {
      font-size: 18px;
    }
  }

  a {
    text-decoration: none;
    color: ${styleConfig.colors.primary};
    cursor: pointer;
  }

  textarea {
    resize: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='${encodeURIComponent(
      styleConfig.colors.primary
    )}' height='1rem' viewBox='0 0 24 24' width='1rem' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5z'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 0;
    border: 2px solid black;
    border-radius: 2px;
    padding: 0.2rem 0.5rem;
    padding-right: 1.5em;
    max-width: 50vw;

    @media(${styleConfig.media.desktop}) {
      max-width: 20vw;
    }
  }
`;
